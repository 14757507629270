import Vue from 'vue'
import VueRouter from 'vue-router'
const HomeComponent = () => import(/* webpackChunkName: "/" */ '@/components/HomeComponent');
const AboutUsComponent = () => import(/* webpackChunkName: "about" */ '@/components/AboutUsComponent.vue');
const AimsAndObjectivesComponent = () => import(/* webpackChunkName: "about" */ '@/components/AimsAndObjectivesComponent');
const FocusAreaComponent = () => import(/* webpackChunkName: "about" */ '@/components/FocusAreaComponent');
const ContactUsComponent = () => import(/* webpackChunkName: "about" */ '@/components/ContactUsComponent');
const VacancyComponent = () => import(/* webpackChunkName: "about" */ '@/components/VacancyComponent');
const GloppiconComponent = () => import(/* webpackChunkName: "about" */ '@/components/GloppiconComponent');
const ServicesComponent = () => import(/* webpackChunkName: "about" */ '@/components/ServicesComponent');
const ProfileComponent = () => import(/* webpackChunkName: "about" */ '@/components/ProfileComponent');
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeComponent
  },
  {
    path: '/about',
    name: 'About',
    component: AboutUsComponent
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUsComponent
  },
  {
    path: '/aims-and-objectives',
    name: 'AimsAndObjectives',
    component: AimsAndObjectivesComponent
  },
  {
    path: '/focus-areas',
    name: 'FocusArea',
    component: FocusAreaComponent
  },
  {
    path: '/gloppicon',
    name: 'Gloppicon',
    component: GloppiconComponent
  },
  {
    path: '/vacancies',
    name: 'Vacancy',
    component: VacancyComponent
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUsComponent
  },
  {
    path: '/services',
    name: 'Services',
    component: ServicesComponent
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileComponent
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
