<template>
  <v-app>
    <v-main>
      <HeaderComponent/>
      <router-view/>
    </v-main>
    <v-divider/>
    <FooterComponent/>
  </v-app>
</template>
<script>
import HeaderComponent from "@/components/HeaderComponent";
import FooterComponent from "@/components/FooterComponent";
export default {
  name: 'App',
  components: {FooterComponent, HeaderComponent},
};
</script>
