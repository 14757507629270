import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
/* eslint-disable no-new */
const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        partners: [],
        projects: [],
        focusAreas: [],
        services: [],
        teamMembers: [],
        boardMembers: [],
        publications: [],
    },
    mutations: {
        MUTATE: (state, payload) => {
            state[payload.state] = payload.data;
        },
    },
    getters: {
        helixGetter: (state) => (setup) => state[setup],
    },
    actions: {
        partners: ({commit}) => {
            axios
                .get(BASE_URL+'partners')
                .then(res => {
                    commit("MUTATE", {
                        state: "partners",
                        data: res.data.data,
                    });
                })
                .catch(err => console.error(err))
        },
        projects: ({commit}) => {
            axios
                .get(BASE_URL+'projects')
                .then(res => {
                    commit("MUTATE", {
                        state: "projects",
                        data: res.data.data,
                    });
                })
                .catch(err => console.error(err))
        },
        focusAreas: ({commit}) => {
            axios
                .get(BASE_URL+'focus-areas')
                .then(res => {
                    commit("MUTATE", {
                        state: "focusAreas",
                        data: res.data.data,
                    });
                })
                .catch(err => console.error(err))
        },
        services: ({commit}) => {
            axios
                .get(BASE_URL+'services')
                .then(res => {
                    commit("MUTATE", {
                        state: "services",
                        data: res.data.data,
                    });
                })
                .catch(err => console.error(err))
        },
        teamMembers: ({commit}) => {
            axios
                .get(BASE_URL+'team-members')
                .then(res => {
                    commit("MUTATE", {
                        state: "teamMembers",
                        data: res.data.data,
                    });
                })
                .catch(err => console.error(err))
        },
        boardMembers: ({commit}) => {
            axios
                .get(BASE_URL+'board-members')
                .then(res => {
                    commit("MUTATE", {
                        state: "boardMembers",
                        data: res.data.data,
                    });
                })
                .catch(err => console.error(err))
        },
        publications: ({commit}) => {
            axios
                .get(BASE_URL+'publications')
                .then(res => {
                    commit("MUTATE", {
                        state: "publications",
                        data: res.data.data,
                    });
                })
                .catch(err => console.error(err))
        },
    },
    modules: {
    }
})

export default store
